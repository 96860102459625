import React, { useEffect, useRef, useState } from 'react';
// import MyProfileHeader from '../../myProfileHeader';
// import MyFirstFlat from '../../myFirstFlat';
import './../myProfile/myProfile.css';
import DeletePopup from '../../deletePopup';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import { BorderAll } from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
// import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DeleteProfilePopup from './deleteProfilePopup';
import { useNavigate } from 'react-router';
import { myprofile2,myorders,myaddress,logout2 } from '../../../assets/img'
// import ImageCropper from "./ImageCropper";

// import FileInput from './FileInput';
// import ImageCropper from './imageCropper';
// import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";

function EditProfile() {

    const notify = (message: string) => toast(message);

    const [profile, setProfile] = useState({})
    const [deleteChange, setDeleteChange] = useState(false)
    const state: any = useSelector(state => state);

    const [nameValue, setName] = useState("")
    const [emailValue, setEmail] = useState("")
    const [mobileValue, setMobile] = useState("")
    const [photo, setPhoto] = useState()

    const [emailDisabled, setEmailDisabled] = useState(true)
    const [mobileDisabled, setMobileDisabled] = useState(true)
    const navigate = useNavigate();

    const rand = Math.random() ;

    const base_url = process.env.REACT_APP_BACKEND_URL;
    // const base_url = "https://revamp.dreamguystech.com";

    // const [cropper, setCropper] = useState<any>();
    // const [avatarUrl, setAvatarUrl] = useState<any>();

    const token = localStorage.getItem("token");

    useEffect(() => {
        axios({
            method: "get",
            url: `${base_url}/api/my_profile`,
            headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
        }).then((response) => {
            if (response.status != 401) {
                setProfile(response.data.data);
                setName(response.data.data.name);
                setEmail(response.data.data.email);
                if (response.data.data.email == '') {
                    setEmailDisabled(false);
                }
                setMobile(response.data.data.mobile);
                if (response.data.data.mobile == '') {
                    setMobileDisabled(false);
                }
            }
        }).catch(function (error) {
            
        })

    }, [state])

    function handlenameChange(event: any) {
        setName(event.target.value)
    }

    function handleEmailChange(event: any) {
        setEmail(event.target.value)
    }

    function handleMobileChange(event: any) {
        setMobile(event.target.value)
    }

    function handlePhotoChange(event: any) {
        setPhoto(event.target.files[0])
        // setAvatarUrl(event.target.files[0])
    }

    function handleSubmit(event: any) {
        event.preventDefault()

        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        }

        let requestBody = {};
        
        if (mobileDisabled) {
            requestBody = { name: nameValue, email: emailValue };
        }else{
            requestBody = { name: nameValue, email: emailValue, mobile: mobileValue };
        }

        axios.post(`${base_url}/api/edit_profile`, requestBody, {
            headers: headers
        }).then(e => {
            if (e.data.Response.response_code == "1" && e.data.Response.response_message == "updated successfully") {
                notify(e.data.Response.response_message)
                setTimeout(() => {
                    navigateToUrl('/myProfile');
                }, 1500);
                return true
            }
            else {
                notify(e.data.Response.response_message)
                // setGenError(e.data.Response.response_message);
                return false
            }
        })
    }

    function handlePhotoSubmit(event: any) {
        event.preventDefault()

        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'content-type': 'multipart/form-data',
        }


        axios.post(`${base_url}/api/edit_profile`, { 'profile_img': photo }, {
            headers: headers
        }).then(e => {
            if (e.data.Response.response_code == "1" && e.data.Response.response_message == "updated successfully") {
                notify(e.data.Response.response_message)
                setTimeout(() => {
                    navigateToUrl('/myProfile');
                }, 1500);
                return true
            }
            else {
                notify(e.data.Response.response_message)
                // setGenError(e.data.Response.response_message);
                return false
            }
        })
    }

    // const getNewAvatarUrl = (e: any) => {
    //     if (e.target.files) {
    //         

    //         // setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
    //     }
    // };


    // const getCropData = async () => {
    //     if (cropper) {
    //         const file = await fetch(cropper.getCroppedCanvas().toDataURL())
    //             .then((res) => res.blob())
    //             .then((blob) => {
    //                 return new File([blob], "newAvatar.png", { type: "image/png" });
    //             });
    //         if (file) {
    //             // authService
    //             //     .uploadAvatar(userId, file)
    //             //     .then(() => {
    //             //         refetchUser(userId);
    //             //         cancelEdit();
    //             //     })
    //             //     .catch((e) => notify(e));
    //         }
    //     }
    // };


    // const profileData: Profile = {profile};

    // const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
    //     obj[key];

    // interface Profile {
    //     id: number;
    //     name: string;
    //     email: string;
    //     mobile: string;
    //     photo: string;
    // }

    // const [image, setImage] = useState("");
    // const [currentPage, setCurrentPage] = useState("choose-img");
    // const [imgAfterCrop, setImgAfterCrop] = useState("");

    // Invoked when new image file is selected
    // const onImageSelected = (selectedImg: any) => {
    //     setImage(selectedImg);
    //     setCurrentPage("crop-img");
    // };

    // Generating Cropped Image When Done Button Clicked
    // const onCropDone = (imgCroppedArea: any) => {
    //     const canvasEle = document.createElement("canvas");
    //     canvasEle.width = imgCroppedArea.width;
    //     canvasEle.height = imgCroppedArea.height;

    //     const context = canvasEle.getContext("2d");

    //     let imageObj1 = new Image();
    //     imageObj1.src = image;
    //     imageObj1.onload = function () {
    //         context!.drawImage(
    //             imageObj1,
    //             imgCroppedArea.x,
    //             imgCroppedArea.y,
    //             imgCroppedArea.width,
    //             imgCroppedArea.height,
    //             0,
    //             0,
    //             imgCroppedArea.width,
    //             imgCroppedArea.height
    //         );

    //         const dataURL = canvasEle.toDataURL("image/jpeg");

    //         setImgAfterCrop(dataURL);
    //         setCurrentPage("img-cropped");
    //     };
    // };

    // Handle Cancel Button Click
    // const onCropCancel = () => {
    //     setCurrentPage("choose-img");
    //     setImage("");
    // };


    // const inputRef = useRef<any>();

    // const handleOnChange = (event: any) => {
    //     if (event.target.files && event.target.files.length > 0) {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(event.target.files[0]);
    //         reader.onload = function (e) {
    //             onImageSelected(reader.result);
    //         };
    //     }
    // };

    // const onChooseImg = () => {
    //     inputRef.current.click();
    // };

    const profileData: { [unit: string]: any } = profile;

    // const [src, setSrc] = useState<any>(null);
    // const [crop, setCrop] = useState<any>({ aspect: 1 / 1 });
    // const [image, setImage] = useState<any>(null);
    // const [output, setOutput] = useState<any>(null);

    // const selectImage = (file: any) => {
    //     setSrc(URL.createObjectURL(file));
    // };

    // const cropImageNow = () => {
    //     const canvas = document.createElement('canvas');
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext('2d');

    //     const pixelRatio = window.devicePixelRatio;
    //     canvas.width = crop.width * pixelRatio;
    //     canvas.height = crop.height * pixelRatio;
    //     ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //     ctx!.imageSmoothingQuality = 'high';

    //     ctx!.drawImage(
    //         image,
    //         crop.x * scaleX,
    //         crop.y * scaleY,
    //         crop.width * scaleX,
    //         crop.height * scaleY,
    //         0,
    //         0,
    //         crop.width,
    //         crop.height,
    //     );

    //     // Converting to base64
    //     const base64Image = canvas.toDataURL('image/jpeg');
    //     setOutput(base64Image);
    // };

    const [imageToCrop, setImageToCrop] = useState<any>(undefined);
    const [croppedImage, setCroppedImage] = useState<any>(undefined);

    const onUploadFile = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                const image = reader.result;

                setImageToCrop(image);
            });

            reader.readAsDataURL(event.target.files[0]);

            handlePhotoChange(event);
        }
    };

    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.clear();
        navigate("/login")
    }

    const navigateToUrl = (url:string) => {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());
    
        let URL = window.location.href.split("/");
        if (URL.length > 4) {
          navigate(`/shop/${finalResult}${url}`);
        } else {
          navigate(url);
        }
    } else {
        navigate(url);
      }
      };

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumpview">
                                <h2>My Profile</h2>
                                <ul>
                                    <li>
                                        <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <span> My Profile</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-myprofile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 custom-myprofile">
                            <h1>My Profile</h1>
                            <span className="float-end"><a href='#' onClick={(e) => { e.preventDefault(); navigateToUrl('/') }}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Back to home</a></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="user-profile">
                                <div className="user-profile-details">
                                    <img src={`${profileData.web_photo}?bust=${rand}`} className="user-profile-img" alt='' />
                                    <form onSubmit={handlePhotoSubmit}>
                                        <input type="hidden" name="img_edit" id="img_edit" />
                                        <div className="change-photo-btn">
                                            <span><i className="fa fa-upload"></i> Upload Photo</span>
                                            <input type="file" className="upload" required onChange={onUploadFile} />
                                        </div>
                                        <img src={imageToCrop} alt="" className='img-fluid mt-2'/>
                                        <div className="">
                                            <button type="submit" className="backto-home hover-btn">Upload</button>
                                            <a href='#' onClick={(e) => { e.preventDefault(); navigateToUrl('/myProfile') }} className="btn cancel-btn hover-btn">Cancel</a>
                                        </div>
                                    </form> 
                                    <h4><small>Hello,</small> {profileData.name}</h4>
                                </div>
                                <div className="orders-account">
                                    <div className="myorders" onClick={() => navigateToUrl('/myProfile')}>
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myProfile')}}>
                                            <i className="fas fa-layer-group"></i>
                                            <h4>My Profile</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myOrders')} >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myOrders')}}>
                                            <i className="fas fa-tag"></i>
                                            <h4>My Orders</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myAddress')}  >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myAddress')}}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <h4>My Address</h4>
                                        </a>
                                    </div>
                                    {/* <div className="myorders">
                                        <button className="btn btn-danger remove_option m-sm-1" type="button" data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></button>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                                    <div className="myorders">
                                        <a href='#' onClick={(e) => { handleLogout(e) }}>
                                            <i className="fas fa-power-off"></i>
                                            <h4>Logout</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="profile-details-right">
                                <div className="profileinfo-header">
                                    <h5>Personal Information</h5>
                                    <span className="float-end"><a href="#"><i className="fas fa-pencil-alt" aria-hidden="true"></i></a></span>
                                </div>
                                <form name="form" className="form-signin" method="POST" onSubmit={handleSubmit}>
                                    <div className="personal-info">
                                        <label>Name:</label>
                                        <input type="text" className='form-control' name="name" id="name" value={nameValue} onChange={handlenameChange} />
                                        {/* <p>{profileData.name}</p> */}
                                    </div>
                                    <div className="personal-info">
                                        <label>Email Address:</label>
                                        <input type="email" className='form-control' name="email" id="email" value={emailValue} onChange={handleEmailChange} disabled={emailDisabled} />
                                        {/* <p>{profileData.email}</p> */}
                                    </div>
                                    <div className="personal-info">
                                        <label>Phone Number:</label>
                                        <input type="tel" placeholder='Mobile Number without Country Code' className='form-control' name="mobile" id="mobile" value={mobileValue} onChange={handleMobileChange} disabled={mobileDisabled} />
                                        {/* <p>{profileData.mobile}</p> */}
                                    </div>
                                    <div className="profile-edit-btn">
                                        <button type="submit" className="backto-home hover-btn">Save</button>
                                        <a href='#' onClick={(e) => { e.preventDefault(); navigateToUrl('/myProfile') }} className="btn cancel-btn hover-btn">Cancel</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteChange && <DeleteProfilePopup close={() => setDeleteChange(false)} />}
            <ToastContainer />
        </div>
    )
}

export default EditProfile;